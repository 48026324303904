<template>
  <div class="group-course">
    <div class="shortcutBtns">
      <router-link :to="{ name: 'GroupCourse' }">
        <el-button type="success">
          {{ $t("pageTitle.GroupCourse") }}
          <span v-if="$store.getters['user/isRole']('super-admin')">（按專案計費）</span>
        </el-button>
      </router-link>
      <router-link :to="{ name: 'privateLessons' }">
        <el-button>
          {{ $t("pageTitle.privateLessons") }}
          <span v-if="$store.getters['user/isRole']('super-admin')">（按鐘點費計費）</span>
        </el-button>
      </router-link>
      <router-link :to="{ name: 'counseling' }">
        <el-button>
          {{ $t("pageTitle.counseling") }}
        </el-button>
      </router-link>
    </div>
    <hr />
    <div
      class="shortcutBtns"
      v-if="$store.getters['user/isRole']('super-admin')"
    >
      <router-link :to="{ name: 'sessionClassesList' }">
        <el-button>班級清單</el-button>
      </router-link>
      <router-link :to="{ name: 'GroupCourse' }">
        <el-button type="success">班級清單和成績</el-button>
      </router-link>
    </div>
    <Breadcrumb />
    <h1>{{ pageTitle }}</h1>
    <hr class="separate-line" />
    <div class="row">
      <div class="col-md-1">
        <h5>梯次</h5>
      </div>
      <div class="col-md-11">
        <el-radio-group
          @change="filterSession"
          v-model="sessionId"
          size="small"
          class="mb-3"
        >
          <el-radio-button :label="-1">
            {{ $t("enrollment.list.all") }}
          </el-radio-button>
          <el-radio-button
            v-for="(item, index) in course_sessions"
            :key="index"
            :label="index"
          >
            <span>{{ helper.displayI18nText($i18n.locale, item.title) }}</span>
          </el-radio-button>
          <router-link
            v-if="isRoleAdmin()"
            style="fontSize: 16px; margin: 8px"
            :to="{ name: 'courseSessionsList' }"
            ><i class="fas fa-edit"
          /></router-link>
        </el-radio-group>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1">
        <h5>小班</h5>
      </div>
      <div class="col-md-11">
        <el-radio-group
          @change="filterClass"
          v-model="classId"
          size="small"
          class="mb-3"
        >
          <el-radio-button :label="-1">
            {{ $t("enrollment.list.all") }}
          </el-radio-button>
          <el-radio-button
            v-for="(item, index) in classes"
            :key="index"
            :label="index"
          >
            {{ item.title }}
          </el-radio-button>
          <router-link
            v-if="isRoleAdmin()"
            style="fontSize: 16px; margin: 8px"
            :to="{ name: 'sessionClassesList' }"
            ><i class="fas fa-edit"
          /></router-link>
        </el-radio-group>
      </div>
    </div>
    <div v-for="(section, index) in selectClass" :key="index">
      <hr />
      <h3>
        <a :href="`/session-classes/${section.id}`">{{ section.title }}</a>
        <a
          v-if="isRoleAdmin()"
          :href="`/session-classes/${section.id}/edit`"
        >
          <span class="action-icon">
            <i class="fa fa-edit"></i>
          </span>
        </a>
      </h3>
    </div>
  </div>
</template>

<script>
import { helper } from "@ivy-way/material";
import courseApi from "@/apis/course";
import roleMixin from "@/mixins/role";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },

  mixins: [roleMixin],
  components: {
    Breadcrumb
  },

  props: [],
  data() {
    return {
      sessionId: -1,
      classId: -1,
      results: [],
      result: 0,
      selectClass: [],
      placementList: {},
      course_sessions: [],
      classes: [],
      test_num_arr: [0, 1, 2, 3, 4, 5, 6],
      gradingList: [],
      practices: [],
      editStudent: false,
      tests: []
    };
  },
  computed: {
    helper() {
      return helper;
    },
    pageTitle() {
      return this.$t("pageTitle.GroupCourse");
    }
  },

  mounted() {
    this.getSessions();
    // this.getAssignTest();
  },

  methods: {
    async getAssignTest() {
      const tests = await courseApi.getExams();
      this.tests = tests.exams;
    },
    async getSessions() {
      const placementList = await courseApi.getClasses();
      this.course_sessions = placementList.course_sessions;
      this.filterSession(this.sessionId);
    },
    getClassesList(sessionId) {
      if (sessionId === -1) {
        this.course_sessions.forEach(element => {
          this.classes = this.selectClass = this.selectClass.concat(
            element.session_classes
          );
        });
      } else {
        this.classes = this.selectClass = this.course_sessions[
          sessionId
        ].session_classes;
      }
    },
    filterSession(sessionId) {
      this.sessionId = sessionId;
      this.getClassesList(this.sessionId);
    },
    filterClass(classId) {
      if (classId === -1) {
        this.selectClass = this.classes;
      } else {
        this.selectClass = [this.classes[classId]];
      }
    }
  }
};
</script>
<style scoped>
.shortcutBtns {
  margin-bottom: 8px;
}

.shortcutBtns > a {
  margin-right: 8px;
}

body /deep/ .el-tooltip__popper.is-dark {
  background: #42a16a;
}
.group-course {
  padding: 20px;
}
.table td {
  padding: 5px;
  vertical-align: middle;
  text-align: center;
}
.table th,
.table td {
  text-align: center;
  border-top: none;
  white-space: nowrap;
}
.add-button {
  display: none;
}
.add-result:hover .add-button {
  display: inline-block;
}
</style>
