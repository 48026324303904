import { render, staticRenderFns } from "./GroupCourse.vue?vue&type=template&id=40118958&scoped=true"
import script from "./GroupCourse.vue?vue&type=script&lang=js"
export * from "./GroupCourse.vue?vue&type=script&lang=js"
import style0 from "./GroupCourse.vue?vue&type=style&index=0&id=40118958&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40118958",
  null
  
)

export default component.exports